import type { OrderlineBundledMobilePlanStateLabelClass } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { type Variants } from '@ds/components/Tag'

export const MOBILE_PLAN_STATE_VARIABLES: Record<
  OrderlineBundledMobilePlanStateLabelClass,
  Variants
> = {
  DEFAULT: 'info',
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
}
