export default {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  title: {
    id: 'dashboard_menu_orders',
    defaultMessage: 'My orders',
  },
  subtitle: {
    id: 'dashboard_menu_orders_subtitle',
    defaultMessage: 'Delivery tracking, customer service, etc.',
  },
}
