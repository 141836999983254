<template>
  <section data-test="dashboard-product">
    <h3 class="heading-3 pb-8">{{ i18n(translations.header) }}</h3>
    <ColumnList full-height>
      <template #leftCol>
        <ShippingItem
          :label="i18n(translations.labelShippingAddress)"
          mobile-full-width
        >
          <template #content>
            <AddressComponent
              :address="shippingData.shippingAddress"
              localize-name-order
              with-details
              with-phone
            />
          </template>
        </ShippingItem>

        <ShippingItem
          :label="i18n(translations.labelBillingAddress)"
          mobile-full-width
        >
          <template #content>
            <AddressComponent
              :address="shippingData.billingAddress"
              localize-name-order
              with-details
              with-phone
            />
          </template>
        </ShippingItem>
      </template>

      <template #rightCol>
        <RevListItemStatic>
          <template #label>
            {{ i18n(translations.labelShippingProvider) }}
          </template>
          <template #suffix>
            {{ shippingData.shippingProvider }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="shippingData.trackingNumber">
          <template #label>
            {{ i18n(translations.labelTrackingNumber) }}
          </template>
          <template #suffix>
            {{ shippingData.trackingNumber }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="shippingData.trackingUrl">
          <template #label>
            {{ i18n(translations.labelTrackingLink) }}
          </template>
          <template #suffix>
            <div class="w-[20ch] truncate lg:w-[40ch]">
              <RevLink :to="shippingData.trackingUrl">
                {{ shippingData.trackingUrl }}
              </RevLink>
            </div>
          </template>
        </RevListItemStatic>
      </template>
    </ColumnList>
  </section>
</template>

<script setup lang="ts">
import AddressComponent from '@backmarket/nuxt-module-address/Address.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevListItemStatic } from '@ds/components/ListItemStatic'

import ColumnList from '../ColumnList/ColumnList.vue'

import translations from './Shipping.translations'
import type { ShippingData } from './Shipping.types'
import ShippingItem from './components/ShippingItem.vue'

const i18n = useI18n()

const props = defineProps<{
  shippingData: ShippingData
}>()

const { shippingData } = props
</script>
