<template>
  <div class="grid grid-cols-2 py-20">
    <div class="mb-16 md:col-span-1" :class="containerClass">
      <span class="text-static-default-hi body-1 w-full max-w-full">
        {{ label }}
      </span>
    </div>

    <div class="mb-16 md:col-span-1" :class="containerClass">
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { tw } from '@backmarket/utils/string/tw'

import type { ShippingItemProps } from './ShippingItem.types'

const props = defineProps<ShippingItemProps>()

const containerClass = {
  [tw`col-span-full`]: props.mobileFullWidth,
}
</script>
