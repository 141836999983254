<template>
  <div :data-qa="`orders-orderitem-${orderline.orderlineId}`">
    <CancelOrderPendingPaymentModal :orderline-id="orderline.orderlineId" />
    <InvoiceRequestedModal :orderline-id="orderline.orderlineId" />
    <MobileServiceRedirectModal
      v-if="
        orderline.bundledMobilePlan &&
        orderline.bundledMobilePlan.partnerPortalUrl
      "
      :mobile-service-name="orderline.bundledMobilePlan.partnerProviderLabel"
      :orderline-id="orderline.orderlineId"
      :redirection-url="orderline.bundledMobilePlan.partnerPortalUrl"
    />

    <RevCard>
      <OrderlineContainer data-test="orderline-container" :orderline />

      <MobilePlan
        v-if="orderline.bundledMobilePlan"
        :bundled-mobile-plan="orderline.bundledMobilePlan"
        data-qa="mobile-plan-orderline"
        data-test="mobile-plan-orderline"
        logo-url="/img/bouygues/bouyguesLogoSquare.svg"
        :orderline-id="orderline.orderlineId"
      />

      <RevDivider class="block" />

      <RevAccordionItem
        :data-qa="`orders-orderitem-${orderline.orderlineId}-showinfo`"
        data-test="orderline-show-more-details"
        @click="getOrderlineDetails"
      >
        <template #title>
          {{ accordionItemTitle }}
        </template>

        <template #body>
          <RevSpinner
            v-if="pending"
            :alternative-text="i18n(translations.loading)"
            class="mx-auto my-12"
            data-test="`orderline-card-details-loader`"
          />

          <Info
            v-if="orderlineDetails && !pending"
            :orderline="orderlineDetails"
          />
        </template>
      </RevAccordionItem>
    </RevCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevSpinner } from '@ds/components/Spinner'

import MobileServiceRedirectModal from '../../../modals/MobileServiceRedirectModal/MobileServiceRedirectModal.vue'

import translations from './OrderlineCard.translations'
import Info from './components/Info/Info.vue'
import MobilePlan from './components/MobilePlan/MobilePlan.vue'
import OrderlineContainer from './components/OrderlineContainer/OrderlineContainer.vue'
import { useOrderlineDetails } from './composables/useOrderlineDetails'
import CancelOrderPendingPaymentModal from './modals/CancelOrderPendingPaymentModal/CancelOrderPendingPaymentModal.vue'
import InvoiceRequestedModal from './modals/InvoiceRequestedModal/InvoiceRequestedModal.vue'

export interface OrderlineCardProps {
  orderline: Orderline
}

const props = defineProps<OrderlineCardProps>()

const i18n = useI18n()

const accordionItemCollapse = ref<boolean>(true)

const {
  data: orderlineDetails,
  execute,
  pending,
} = useOrderlineDetails({
  orderlineId: props.orderline.publicId,
})

const accordionItemTitle = computed(() =>
  accordionItemCollapse.value
    ? i18n(translations.orderlineCardSeeMoreDetails)
    : i18n(translations.orderlineCardHideMoreDetails),
)

async function getOrderlineDetails() {
  if (accordionItemCollapse.value) {
    await execute()
  }

  accordionItemCollapse.value = !accordionItemCollapse.value
}
</script>
