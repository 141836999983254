export default {
  cta: {
    id: 'dashboard_order_refunded_cta',
    defaultMessage: 'Learn more about our refund process',
  },
  description: {
    id: 'dashboard_order_refunded_description_text',
    defaultMessage:
      'Please keep in mind it can take a few days for the refund to appear in your account.',
  },
  title: {
    id: 'dashboard_orderline_refunded_title',
    defaultMessage: 'This order was refunded on {date}.',
  },
}
