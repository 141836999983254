<template>
  <div class="body-1 pb-24 lg:pb-0" data-test="orderline-card-content-replaced">
    <p class="mb-24">
      {{
        i18n(translations.paragraph1, {
          replacementOrderId: props.replacementOrderline.orderId,
        })
      }}
    </p>

    <p class="mb-24">
      {{
        i18n(translations.paragraph2, {
          replacementOrderId: props.replacementOrderline.orderId,
        })
      }}
    </p>

    <p class="mb-24">
      <RevLink target="_blank" :to="i18n(translations.faqLinkUrl)">
        {{ i18n(translations.faqLinkLabel) }}
      </RevLink>
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { OrderlineLink } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './OrderlineCardReplaced.translations'

export interface OrderlineCardReplacedProps {
  replacementOrderline: OrderlineLink
}

const props = defineProps<OrderlineCardReplacedProps>()

const i18n = useI18n()
</script>
