<template>
  <div class="flex w-full flex-col space-y-4" data-test="orderline-header">
    <p class="caption" data-test="orderline-id">
      {{
        i18n(translations.orderlineIdLabel, {
          orderId: orderId,
        })
      }}
    </p>

    <RevTag
      v-if="orderlineStateTag"
      class="w-fit"
      data-test="orderline-status"
      :label="orderlineStateTag.label"
      :variant="orderlineStateTag.variant"
    />

    <p v-if="productTitle" class="body-1-bold">{{ productTitle }}</p>

    <p v-if="orderlinePrice" class="body-2">
      {{ i18n.price(orderlinePrice) }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag, type Variants as RevTagVariants } from '@ds/components/Tag'

import { API_TAG_LABEL_CLASS_TO_TAG_VARIANT } from '../../OrderlineCard.constants'

import translations from './OrderlineCardHeader.translations'

export interface OrderlineCardHeaderProps {
  productTitle: string
  orderId: number
  orderlinePrice: Price | null
  orderlineState: Orderline['state']
  orderlineTag: Orderline['tag']
}

const props = defineProps<OrderlineCardHeaderProps>()

const i18n = useI18n()

const orderlineStateTag = computed(() => {
  if (props.orderlineTag !== null) {
    return {
      label: props.orderlineTag.label,
      variant: API_TAG_LABEL_CLASS_TO_TAG_VARIANT[
        props.orderlineTag.labelClass || 'DEFAULT'
      ] as RevTagVariants,
    }
  }

  switch (props.orderlineState.enumState) {
    case 'CANCELLED':
      return {
        label: i18n(translations.orderlineCancelTagLabel),
        variant: 'danger' as RevTagVariants,
      }
    case 'REFUNDED':
      return {
        label: i18n(translations.orderlineRefundedTagLabel),
        variant: 'secondary' as RevTagVariants,
      }
    default:
      return undefined
  }
})
</script>
