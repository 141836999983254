export default {
  orderlineIdLabel: {
    id: 'dashboard_order_item_title_ff_tetris',
    defaultMessage: 'Order # {orderId}',
  },
  orderlineCancelTagLabel: {
    id: 'dashboard_orderline_cancel_tag_title',
    defaultMessage: 'Cancelled',
  },
  orderlineRefundedTagLabel: {
    id: 'dashboard_orderline_refunded_tag_title',
    defaultMessage: 'Refunded',
  },
}
