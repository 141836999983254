<template>
  <div>
    <RevButton
      v-if="!mobilePlanAction.hint"
      :data-qa="`mobile-plan-action-ask-for-help`"
      :disabled="!mobilePlanAction.active"
      full-width="always"
      variant="primary"
      @click="performAction"
    >
      {{ mobilePlanAction.label }}
    </RevButton>

    <RevTooltip v-if="mobilePlanAction.hint">
      <template #trigger="{ show, hide }">
        <RevButton
          :data-qa="`mobile-plan-action-ask-for-help`"
          :disabled="!mobilePlanAction.active"
          full-width="always"
          variant="primary"
          @click="performAction"
          @focusin="show"
          @focusout="hide"
          @mouseleave="hide"
          @mouseover="show"
        >
          {{ mobilePlanAction.label }}
        </RevButton>
      </template>

      {{ mobilePlanAction.hint }}
    </RevTooltip>
  </div>
</template>

<script lang="ts" setup>
import { RevButton } from '@ds/components/Button'
import { RevTooltip } from '@ds/components/Tooltip'

import { MOBILE_PLAN_ACTIONS_CONFIG } from '../MobilePlanActions.config'

import type { MobilePlanActionProps } from './MobilePlanAction.types'

const props = defineProps<MobilePlanActionProps>()

async function performAction(): Promise<void> {
  const config = MOBILE_PLAN_ACTIONS_CONFIG[props.mobilePlanAction.type](
    props.mobilePlanAction,
  )

  if (config.onClick) {
    const { onClick } = config

    await onClick({ orderlineId: props.orderlineId })
  }
}
</script>
