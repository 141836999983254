<template>
  <section data-test="dashboard-product">
    <h3 class="heading-3 pb-8">{{ i18n(translations.header) }}</h3>
    <ColumnList :full-height="false">
      <template #leftCol>
        <RevListItemStatic>
          <template #label>
            {{ i18n(translations.labelQuantity) }}
          </template>
          <template #suffix>
            {{ productData.quantity }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic>
          <template #label>
            {{ i18n(translations.labelGrade) }}
          </template>
          <template #suffix>
            {{ productData.grade }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="productData.IMEI">
          <template #label>
            {{ i18n(translations.labelIMEI) }}
          </template>
          <template #suffix>
            {{ productData.IMEI }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="productData.serialNumber">
          <template #label>
            {{ i18n(translations.labelSerialNumber) }}
          </template>
          <template #suffix>
            {{ productData.serialNumber }}
          </template>
        </RevListItemStatic>
      </template>

      <template #rightCol>
        <RevListItemStatic v-if="productData.sellerLink">
          <template #label>
            {{ i18n(translations.labelSeller) }}
          </template>
          <template #suffix>
            <RevLink :to="productData.sellerLink">
              {{ productData.sellerName }}
            </RevLink>
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="productData.price">
          <template #label>
            {{ i18n(translations.labelPrice) }}
          </template>
          <template #suffix>
            {{ i18n.price(productData.price) }}
          </template>
        </RevListItemStatic>

        <RevListItemStatic v-if="productData.priceShipping">
          <template #label>
            {{ i18n(translations.labelShippingFee) }}
          </template>
          <template #suffix>
            {{ i18n.price(productData.priceShipping) }}
          </template>
        </RevListItemStatic>
      </template>
    </ColumnList>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevListItemStatic } from '@ds/components/ListItemStatic'

import ColumnList from '../ColumnList/ColumnList.vue'

import translations from './Product.translations'
import type { ProductData } from './Product.types'

const i18n = useI18n()

const props = defineProps<{ productData: ProductData }>()

const { productData } = props
</script>
