<template>
  <RevContainer>
    <div class="mb-32 space-y-4 text-center">
      <h2 class="heading-1">{{ i18n(translations.title) }}</h2>

      <p class="body-1">{{ i18n(translations.subtitle) }}</p>
    </div>

    <div
      v-if="hasOrderlines"
      class="space-y-20 md:space-y-32"
      data-qa="order-list"
    >
      <OrderlineCard
        v-for="orderline in orderlines"
        :key="orderline.orderlineId"
        :orderline
      />

      <div v-if="canLoadMore" class="flex items-center justify-center">
        <LoadMore :is-loading="pending" @load-more="loadMore" />
      </div>
    </div>

    <div v-if="initialLoading" class="flex items-center justify-center">
      <RevSpinner
        :alternative-text="i18n(translations.loading)"
        data-test="my-orders-initial-loading"
      />
    </div>

    <EmptyOrderlines v-if="hasNoOrderlines" data-qa="empty-orderlines" />
  </RevContainer>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useIntercomStore } from '@backmarket/nuxt-layer-care-platform/stores/intercom/useIntercomStore'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'
import { RevSpinner } from '@ds/components/Spinner'
import { storeToRefs } from 'pinia'

import { LoadMore } from '#components'
import OrderlineCard from '@/scopes/care-commons/components/order/OrderlineCard/OrderlineCard.vue'

import translations from './MyOrders.translations'
import EmptyOrderlines from './components/EmptyOrderlines/EmptyOrderlines.vue'
import { useOrderlines } from './composables/useOrderlines'

const i18n = useI18n()
const route = useRoute()

const { canLoadMore, execute, loadMore, orderlines, pending } = useOrderlines()

await execute()

const hasOrderlines = computed(() => !isEmpty(orderlines.value))

const hasNoOrderlines = computed(() => !hasOrderlines.value && !pending.value)

const initialLoading = computed(() => pending.value && !hasOrderlines.value)

const intercomStore = useIntercomStore()
const { intercom } = storeToRefs(intercomStore)
const { showConversation } = intercomStore

watch(
  () => intercom.value.isAvailable,
  (isAvailable) => {
    if (isAvailable && route.query.intercom_ticket_id) {
      showConversation(route.query.intercom_ticket_id.toString())
    }
  },
  { immediate: true },
)
</script>
