export default {
  cta: {
    id: 'dashboard_no_order_button_buy',
    defaultMessage: 'Adopt a Product',
  },
  title: {
    id: 'dashboard_no_orderlines_title',
    defaultMessage: "It's pretty hard to believe.",
  },
  subtitle: {
    id: 'dashboard_no_order_subtitle',
    defaultMessage:
      "But it looks like you haven't purchased anything on Back Market yet.",
  },
}
