<template>
  <RevInfoBlock
    :icon="IconWarning"
    :title="i18n(translations.infoTitle)"
    variant="warning"
  >
    <p class="text-static-default-mid body-2">
      {{ i18n(translations.infoContent) }}
    </p>

    <RevButton size="small" :to="coveragePaymentFailureLink" variant="primary">
      {{ i18n(translations.infoCta) }}
    </RevButton>
  </RevInfoBlock>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import { INSURANCE } from '~/scopes/insurance/routes.constants'

import translations from './CoveragePaymentAdvertisement.translations'
import type { CoveragePaymentAdvertisementProps } from './CoveragePaymentAdvertisement.types'

const props = defineProps<CoveragePaymentAdvertisementProps>()

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const coveragePaymentFailureLink = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: INSURANCE.DUE_PAYMENT,
    params: {
      policyId: props.policyId,
    },
  }),
)
</script>
