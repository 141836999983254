export default {
  header: {
    id: 'dashboard_order_review_question',
    defaultMessage: 'What did you think of your purchase?',
  },
  cta: {
    id: 'dashboard_order_give_review',
    defaultMessage: 'Submit a review',
  },
}
