<template>
  <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-56">
    <RevList :class="classes">
      <slot name="leftCol"></slot>
    </RevList>
    <RevList class="border-t-0 md:border-t-1" :class="classes">
      <slot name="rightCol"></slot>
    </RevList>
  </div>
</template>

<script setup lang="ts">
import { type VNode } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevList } from '@ds/components/List'

/**
 * Component which renders a 2 column RevList, and switches their content to 1 col
 * for mobile breakpoint.
 *
 * @example
 * <ColumnList :full-height=false>
 *     <template #leftCol>
 *         <RevListItemStatic />
 *     </template>
 *     <template #rightCol>
 *         <RevListItemStatic />
 *     </template>
 * </ColumnList>
 */

defineSlots<{
  // one or more RevListItemStatic
  'leftCol'(): VNode
  // one or more RevListItemStatic
  'rightCol'(): VNode
}>()

const props = withDefaults(
  defineProps<{
    /**
     * Whether every column should stretch to take up full height of the component.
     *
     * In case we want a more "tabular" looking list then this should be `false`.
     */
    fullHeight?: boolean
  }>(),
  {
    fullHeight: false,
  },
)

const classes = {
  [tw`self-start`]: !props.fullHeight,
}
</script>
