import type { OrderlineDetailed } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineDetailsResponse'

import type { ProductData } from '../components/Product/Product.types'
import type { ShippingData } from '../components/Shipping/Shipping.types'

export function orderlineToShippingData(
  orderline: OrderlineDetailed,
): ShippingData {
  return {
    shippingAddress: {
      city: orderline.shipping.address.city,
      company: orderline.shipping.address.company ?? undefined,

      country: orderline.shipping.address.country,
      countryDialInCode: orderline.shipping.address.countryDialInCode,
      firstName: orderline.shipping.address.firstName,
      firstNamePronunciation:
        orderline.shipping.address.firstNamePronunciation ?? undefined,
      lastName: orderline.shipping.address.lastName,
      lastNamePronunciation:
        orderline.shipping.address.lastNamePronunciation ?? undefined,
      phone: orderline.shipping.address.phone,
      postalCode: orderline.shipping.address.postalCode,
      stateOrProvince: orderline.shipping.address.stateOrProvince ?? '',
      street: orderline.shipping.address.street,
      street2: orderline.shipping.address.street2 ?? undefined,
    },
    billingAddress: {
      city: orderline.billing.address.city,
      company: orderline.billing.address.company ?? undefined,
      country: orderline.billing.address.country,
      countryDialInCode: orderline.billing.address.countryDialInCode,
      firstName: orderline.billing.address.firstName,
      firstNamePronunciation:
        orderline.billing.address.firstNamePronunciation ?? undefined,
      lastName: orderline.billing.address.lastName,
      lastNamePronunciation:
        orderline.billing.address.lastNamePronunciation ?? undefined,
      phone: orderline.billing.address.phone,
      postalCode: orderline.billing.address.postalCode,
      stateOrProvince: orderline.billing.address.stateOrProvince ?? '',
      street: orderline.billing.address.street,
      street2: orderline.billing.address.street2 ?? undefined,
    },
    shippingProvider: orderline.shipping.carrierName,
    trackingNumber: orderline.shipping.trackingNumber,
    trackingUrl: orderline.shipping.trackingUrl,
  }
}

export function orderlineToProductData(
  orderline: OrderlineDetailed,
): ProductData {
  return {
    quantity: orderline.quantity,
    grade: orderline.backboxGrade.name,
    sellerName: orderline.seller.name,
    sellerLink: orderline.seller.linkLanding.href,
    price: orderline.price ?? undefined,
    priceShipping: orderline.shipping.price,
    IMEI:
      orderline?.itemIdentifier?.type === 'IMEI'
        ? orderline.itemIdentifier.value
        : undefined,
    serialNumber:
      orderline?.itemIdentifier?.type === 'SERIAL_NUMBER'
        ? orderline.itemIdentifier.value
        : undefined,
  }
}

export function orderlineCanBeReviewed(
  actions: OrderlineDetailed['detailedActions'],
) {
  return actions.some((action) => action.type === 'rate_order')
}
