export default {
  askOrderBill: {
    id: 'dashboard_order_ask_order_bill',
    defaultMessage: 'Request an Invoice',
  },
  askOrderBillPending: {
    id: 'dashboard_order_pending_order_bill',
    defaultMessage: 'Invoice request pending',
  },
  availableAfterPaymentTooltip: {
    id: 'dashboard_order_available_after_payment_tooltip',
    defaultMessage: 'Available after payment',
  },
  backlabelBill: {
    id: 'dashboard_order_backlabel_bill',
    defaultMessage: 'BackLabel Invoice',
  },
  chillpackBill: {
    id: 'dashboard_order_chillpack_bill',
    defaultMessage: 'Damage & Theft Insurance Invoices',
  },
  deliveryForm: {
    id: 'dashboard_order_delivery_form',
    defaultMessage: 'Download order details',
  },
  invoiceDownload: {
    id: 'dashboard_order_button_invoice_dl',
    defaultMessage: 'Invoice',
  },
  invoiceServiceFeeDownload: {
    id: 'dashboard_order_button_service_fee_invoice_dl',
    defaultMessage: 'Service Fee Invoice',
  },
  pendingOrderBill: {
    id: 'dashboard_order_pending_order_bill',
    defaultMessage: 'Invoice request pending',
  },
  saleCertificate: {
    id: 'dashboard_order_button_sale_certificate',
    defaultMessage: 'Proof of payment',
  },
  trackTooltip: {
    id: 'dashboard_order_button_track_tooltip',
    defaultMessage: 'Available once the seller ships your order',
  },
  trustpackBill: {
    id: 'dashboard_order_trustpack_bill',
    defaultMessage: 'Warranty Invoices',
  },
  trustpackPlusBill: {
    id: 'dashboard_order_trustpackplus_bill',
    defaultMessage: 'Invoice TrustpackPlus',
  },
  trustpackPlusRefundBill: {
    id: 'dashboard_order_trustpackplus_refund_bill',
    defaultMessage: 'Download TrustPack',
  },
  warranty: {
    id: 'dashboard_order_button_warranty',
    defaultMessage: 'Warranty certificate',
  },
}
