<template>
  <div v-if="hasActions" class="space-y-12" data-test="orderline-actions">
    <OrderlineCardAction
      v-for="(action, index) in validActions"
      :key="action.type"
      :orderline-action="action"
      :orderline-id
      :publicId
      :variant="getVariant(index)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import {
  ORDERLINE_ACTION,
  type Orderline,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import OrderlineCardAction from './components/OrderlineCardAction.vue'

export interface OrderlineCardActionsProps {
  orderlineActions: Orderline['actions']
  orderlineId: Orderline['orderlineId']
  publicId: Orderline['publicId']
  onlySecondaryCta?: boolean
}

const props = withDefaults(defineProps<OrderlineCardActionsProps>(), {
  onlySecondaryCta: false,
})

const hasActions = computed(() => !isEmpty(props.orderlineActions))

const validActions = computed(() =>
  props.orderlineActions.filter((action) =>
    Object.values(ORDERLINE_ACTION).includes(action.type),
  ),
)

const getVariant = (index: number) => {
  if (props.onlySecondaryCta) {
    return 'secondary'
  }

  return index === 0 ? 'primary' : 'secondary'
}
</script>
