<template>
  <div class="body-1 pb-24 lg:pb-0" data-test="orderline-card-content-cancel">
    <p class="mb-24">
      {{
        i18n(translations.title, {
          date: date,
        })
      }}
    </p>

    <p>{{ i18n(translations.description) }}</p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type DateTime } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './OrderlineCardCancel.translations'

export interface OrderlineCardCancelProps {
  refundDate: DateTime
}

const props = defineProps<OrderlineCardCancelProps>()

const i18n = useI18n()

const date = computed(() =>
  i18n.date(new Date(props.refundDate), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }),
)
</script>
