export const ORDERLINE_CARD_MODAL = {
  cancelOrderWithPendingPayment: 'cancelOrderWithPendingPayment',
  invoiceRequested: 'invoiceRequested',
  mobilePlanAskForHelpModal: 'mobilePlanAskForHelpModal',
}

export const API_TAG_LABEL_CLASS_TO_TAG_VARIANT = {
  DEFAULT: 'secondary',
  INFO: 'info',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
}
