<template>
  <div>
    <RevButton
      v-if="!action.tooltip"
      :data-qa="`orderline-action-${action.type}`"
      :disabled="action.disabled"
      full-width="always"
      :to="action.to"
      variant="secondary"
      @click="performAction"
    >
      {{ i18n(action.label) }}
    </RevButton>
    <RevTooltip v-if="action.tooltip">
      <template #trigger="{ show, hide }">
        <RevButton
          :data-qa="`orderline-action-${action.type}`"
          :disabled="action.disabled"
          full-width="always"
          :to="action.to"
          variant="secondary"
          @click="performAction"
          @focusin="show"
          @focusout="hide"
          @mouseleave="hide"
          @mouseover="show"
        >
          {{ i18n(action.label) }}
        </RevButton>
      </template>

      {{ i18n(action.tooltip) }}
    </RevTooltip>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevTooltip } from '@ds/components/Tooltip'

import { type DocumentAction } from '../../composables/useDocumentActionsConfig'

const i18n = useI18n()

const { action } = defineProps<{
  action: DocumentAction
}>()

const emit = defineEmits<{
  (event: 'request-order-bill'): void
}>()

function performAction(): void {
  action.onClick(emit)
}
</script>
