export default {
  durationLabel: {
    id: 'my_order_info_coverage_duration_label',
    defaultMessage: 'Duration',
  },
  endDateLabel: {
    id: 'my_order_info_coverage_end_date_label',
    defaultMessage: 'End date',
  },
  priceLabel: {
    id: 'my_order_info_coverage_price_label',
    defaultMessage: 'Price',
  },
  protectionLabel: {
    id: 'my_order_info_coverage_protection_label',
    defaultMessage: 'Protection',
  },
  seeTermAndConditionsCta: {
    id: 'my_order_info_coverage_see_term_and_conditions_link',
    defaultMessage: 'See Terms and Conditions',
  },
  startDateLabel: {
    id: 'my_order_info_coverage_start_date_label',
    defaultMessage: 'Start date',
  },
  statusLabel: {
    id: 'my_order_info_coverage_protection_status',
    defaultMessage: 'Status',
  },
}
