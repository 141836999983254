export default {
  header: {
    id: 'my_order_info_shipping_header',
    defaultMessage: 'Shipping info',
  },
  labelBillingAddress: {
    id: 'my_order_info_shipping_label_billing_address',
    defaultMessage: 'Billing address',
  },
  labelShippingAddress: {
    id: 'my_order_info_shipping_label_shipping_address',
    defaultMessage: 'Shipping address',
  },
  labelShippingProvider: {
    id: 'my_order_info_shipping_label_shipping_provider',
    defaultMessage: 'Shipping provider',
  },
  labelTrackingLink: {
    id: 'my_order_info_shipping_label_tracking_link',
    defaultMessage: 'Tracking link',
  },
  labelTrackingNumber: {
    id: 'my_order_info_shipping_label_tracking_number',
    defaultMessage: 'Tracking number',
  },
}
