export default {
  cta: {
    id: 'dashboard_order_invoice_button',
    defaultMessage: 'Great',
  },
  title: {
    id: 'dashboard_order_invoice_title',
    defaultMessage: 'Your invoice request',
  },
  first: {
    id: 'dashboard_order_invoice_first',
    defaultMessage: 'Voila! Your request has been sent to the seller.',
  },
  second: {
    id: 'dashboard_order_invoice_second',
    defaultMessage:
      'The merchant will soon make the invoice available on your Back Market account.',
  },
  third: {
    id: 'dashboard_order_invoice_third',
    defaultMessage:
      "We'll also keep you posted through email, just to be on the safe side.",
  },
}
