<template>
  <div class="flex w-full flex-col space-y-4" data-test="mobile-plan-content">
    <RevTag
      v-if="state.label"
      class="w-fit"
      :label="state.label"
      :variant="tagVariant"
    />

    <h2 class="body-1-bold">{{ mobilePlanOfferLabel }}</h2>

    <p class="body-2-bold">{{ contractCommitmentLabel }}</p>

    <p v-if="priceLabel" class="body-2">{{ priceLabel }}</p>

    <p v-if="priceSubLabel" class="caption">{{ priceSubLabel }}</p>
  </div>
</template>

<script lang="ts" setup>
import { RevTag } from '@ds/components/Tag'

import { MOBILE_PLAN_STATE_VARIABLES } from './MobilePlanContent.config'
import type { MobilePlanContentProps } from './MobilePlanContent.types'

const props = defineProps<MobilePlanContentProps>()

const tagVariant = props.state.labelClass
  ? MOBILE_PLAN_STATE_VARIABLES[props.state.labelClass]
  : 'info'
</script>
