import type { OrderlineDetailed } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineDetailsResponse'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { noop } from '@backmarket/utils/function/noop'

import translations from '../components/DocumentAction/DocumentAction.translations'

// since 'rate_order' should not be a CTA we exclude it from the types
type ActionType = Exclude<
  OrderlineDetailed['detailedActions'][number]['type'],
  'rate_order'
>
// override the 'type' property to not include 'rate_order'
export type Action = Omit<
  OrderlineDetailed['detailedActions'][number],
  'type'
> & {
  type: ActionType
}

export interface DocumentAction {
  label: I18nDefinition
  onClick: (emit: (event: 'request-order-bill') => void) => void
  withoutIcon?: boolean
  disabled?: boolean
  tooltip?: I18nDefinition
  to?: string
  type: ActionType
}

type DocumentActionsConfig = {
  [key in ActionType]: (
    item: Action,
    hasRequestedBill: boolean,
  ) => DocumentAction
}

export function useDocumentActionsConfig(): DocumentActionsConfig {
  return {
    sale_certificate: ({ active, url, type }) => ({
      disabled: !active,
      label: translations.saleCertificate,
      tooltip: active ? undefined : translations.availableAfterPaymentTooltip,
      onClick: () => {
        if (url) {
          window.open(url, '_blank')
        }
      },
      type,
    }),
    orderline_warranty: ({ url, type }) => ({
      label: translations.warranty,
      onClick: noop,
      to: url ?? undefined,
      type,
    }),
    pending_order_bill: ({ type }) => ({
      disabled: true,
      label: translations.pendingOrderBill,
      withoutIcon: true,
      onClick: noop,
      type,
    }),
    order_bill: ({ url, type }) => ({
      label: translations.invoiceDownload,
      onClick: () => {
        if (url) {
          window.open(url, '_blank')
        }
      },
      type,
    }),
    delivery_form: ({ url, type }) => ({
      label: translations.deliveryForm,
      onClick: () => {
        if (url) {
          window.open(url, '_blank')
        }
      },
      type,
    }),
    backlabel_bill: ({ url, type }) => ({
      label: translations.backlabelBill,
      onClick: () => {
        if (url) {
          window.open(url, '_blank')
        }
      },
      type,
    }),
    ask_order_bill: ({ active, type }, hasRequestedBill) => ({
      disabled: hasRequestedBill || !active,
      label: hasRequestedBill
        ? translations.askOrderBillPending
        : translations.askOrderBill,
      tooltip: active ? undefined : translations.trackTooltip,
      withoutIcon: true,
      onClick: (emit) => {
        emit('request-order-bill')
      },
      type,
    }),
    service_fee_bill: ({ active, url, type }) => ({
      disabled: !active,
      label: translations.invoiceServiceFeeDownload,
      tooltip: active ? undefined : translations.availableAfterPaymentTooltip,
      onClick: () => {
        if (url) {
          window.open(url, '_blank')
        }
      },
      type,
    }),
  }
}
