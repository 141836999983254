import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import { ROUTES } from '../../route-names'

export function useBuildReviewCollectionLink() {
  const resolveLocalizedRoute = useRouteLocationWithLocale()

  function buildReviewCollectionLink({
    id,
    utmCampaign,
  }: {
    id: string
    utmCampaign?: string
  }) {
    return resolveLocalizedRoute({
      name: ROUTES.FORM,
      params: {
        orderlineId: id,
      },
      query: {
        utm_campaign: utmCampaign,
      },
    })
  }

  return { buildReviewCollectionLink }
}
