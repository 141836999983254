export default {
  infoContent: {
    id: 'my_order_info_monthly_insurance_infoblock_payment_failed_content',
    defaultMessage:
      'It looks like the payment didn’t go through. Double check your order details, and try again.',
  },
  infoCta: {
    id: 'my_order_info_monthly_insurance_infoblock_payment_failed_cta',
    defaultMessage: 'Update payment',
  },
  infoTitle: {
    id: 'my_order_info_monthly_insurance_infoblock_payment_failed_title',
    defaultMessage: 'Payment needed for protection plan',
  },
}
