<template>
  <div class="body-1 pb-24 lg:pb-0" data-test="orderline-card-content-refund">
    <p class="mb-24">
      {{
        i18n(translations.title, {
          date: date,
        })
      }}
    </p>

    <p class="mb-24">{{ i18n(translations.description) }}</p>

    <RevLink class="body-1-link" target="_blank" :to="articleUrl">
      {{ i18n(translations.cta) }}
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { useZendeskFaqBaseUrl } from '#imports'
import { computed } from 'vue'

import { type DateTime } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './OrderlineCardRefund.translations'

export interface OrderlineCardRefundProps {
  refundDate: DateTime
}

const props = defineProps<OrderlineCardRefundProps>()

const i18n = useI18n()

const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const articleUrl = zendeskFaqBaseUrl.concat(
  '/articles/4409695847698-How-do-refunds-work',
)

const date = computed(() =>
  i18n.date(new Date(props.refundDate), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }),
)
</script>
