export default {
  title: {
    id: 'dashboard_order_cancel_order_modal_title',
    defaultMessage: 'Cancel my order',
  },
  subTitle: {
    id: 'dashboard_order_cancel_order_modal_subtitle',
    defaultMessage: 'Sit back and relax',
  },

  content: {
    id: 'dashboard_order_cancel_order_modal_content',
    defaultMessage:
      "It looks like you haven't paid for your item yet, so your order will be automatically canceled. No need to lift a finger.",
  },
}
