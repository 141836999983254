export default {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  orderlineCardHideMoreDetails: {
    id: 'dashboard_orderline_card_hide_more_details',
    defaultMessage: 'Hide details',
  },
  orderlineCardSeeMoreDetails: {
    id: 'dashboard_orderline_card_see_more_details',
    defaultMessage: 'See order details',
  },
}
