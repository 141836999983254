<template>
  <RevCard class="p-32">
    <RevContentBlock
      :button-label="i18n(translations.cta)"
      :has-rounded-image="true"
      :image-props="{
        alt: 'illustration-empty-orderlines',
        height: 222,
        width: 448,
        src: '/img/common/not-found-block.svg',
      }"
      :title="i18n(translations.title)"
      title-tag="h2"
      title-variant="title2"
      :to="resolveLocalizedRoute({ name: HOME })"
    >
      <template #default>
        {{ i18n(translations.subtitle) }}
      </template>
    </RevContentBlock>
  </RevCard>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { HOME } from '~/scopes/home/route-names'

import translations from './EmptyOrderlines.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
</script>
