export default {
  description: {
    id: 'dashboard_order_cancel_description',
    defaultMessage:
      'The time it takes for your refund to appear in your account depends on your payment method and bank.',
  },
  title: {
    id: 'dashboard_orderline_cancel_title',
    defaultMessage: 'This command was cancelled the {date}.',
  },
}
