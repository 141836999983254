<template>
  <h4 v-if="coverage.label" class="body-1 pb-8">{{ coverage.label }}</h4>

  <ColumnList full-height>
    <template #leftCol>
      <RevListItemStatic v-if="coverage.status">
        <template #label> {{ i18n(translations.statusLabel) }} </template>

        <template #suffix>
          <RevTag :label="coverage.status" variant="warning" />
        </template>
      </RevListItemStatic>

      <RevListItemStatic v-if="coverage.duration">
        <template #label> {{ i18n(translations.durationLabel) }} </template>

        <template #suffix>{{ coverage.duration }} </template>
      </RevListItemStatic>

      <RevListItemStatic v-if="coverage.startDate">
        <template #label> {{ i18n(translations.startDateLabel) }} </template>

        <template #suffix>
          {{ formatCoverageDate(coverage.startDate) }}
        </template>
      </RevListItemStatic>

      <RevListItemStatic v-if="coverage.price">
        <template #label> {{ i18n(translations.priceLabel) }} </template>

        <template #suffix>{{ coverage.price }} </template>
      </RevListItemStatic>
    </template>

    <template #rightCol>
      <RevListItemStatic v-if="coverage.kind">
        <template #label> {{ i18n(translations.protectionLabel) }} </template>

        <template #suffix> {{ coverage.kind }}</template>
      </RevListItemStatic>

      <RevListItemStatic v-if="coverage.endDate">
        <template #label> {{ i18n(translations.endDateLabel) }} </template>

        <template #suffix>
          <span data-test="end-date">
            {{ formatCoverageDate(coverage.endDate) }}
          </span>
        </template>
      </RevListItemStatic>
    </template>
  </ColumnList>

  <RevLink
    v-if="coverage.termsAndConditionsUrl"
    class="body-1-link block pt-12"
    target="_blank"
    :to="coverage.termsAndConditionsUrl.href"
  >
    {{ i18n(translations.seeTermAndConditionsCta) }}
  </RevLink>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevTag } from '@ds/components/Tag'

import ColumnList from '../../ColumnList/ColumnList.vue'

import translations from './Coverage.translations'
import type { CoverageProps } from './Coverage.types'

const { coverage } = defineProps<CoverageProps>()

const i18n = useI18n()

const formatCoverageDate = (date: string) => {
  return i18n.date(new Date(date), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}
</script>
