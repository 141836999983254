export default {
  header: {
    id: 'my_order_info_product_header',
    defaultMessage: 'Item info',
  },
  labelQuantity: {
    id: 'my_order_info_product_label_quantity',
    defaultMessage: 'Quantity',
  },
  labelPrice: {
    id: 'my_order_info_product_label_price',
    defaultMessage: 'Price',
  },
  labelShippingFee: {
    id: 'my_order_info_product_label_shipping_fee',
    defaultMessage: 'Shipping fee',
  },
  labelSeller: {
    id: 'my_order_info_product_label_seller',
    defaultMessage: 'Seller',
  },
  labelGrade: {
    id: 'my_order_info_product_label_grade',
    defaultMessage: 'Grade',
  },
  labelIMEI: {
    id: 'my_order_info_product_label_imei',
    defaultMessage: 'IMEI',
  },
  labelSerialNumber: {
    id: 'my_order_info_product_label_serial_number',
    defaultMessage: 'Serial number',
  },
}
