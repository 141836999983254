export default {
  paragraph1: {
    id: 'dashboard_orderline_content_replaced_paragraph1',
    defaultMessage:
      'This order was canceled by the seller. It’s been replaced by Order #{replacementOrderId}, supplied by a new seller.',
  },
  paragraph2: {
    id: 'dashboard_orderline_content_replaced_paragraph2',
    defaultMessage:
      'For support or delivery tracking, go to Order #{replacementOrderId}.',
  },
  faqLinkLabel: {
    id: 'dashboard_orderline_content_replaced_faq_link_label',
    defaultMessage: 'See how replacements work',
  },
  faqLinkUrl: {
    id: 'dashboard_orderline_content_replaced_faq_link_url',
    defaultMessage: 'https://www.backmarket.com/en-us/help',
  },
}
