export default {
  title: {
    id: 'dashboard_order_invoice_title',
    defaultMessage: 'Your invoice request',
  },
  text1: {
    id: 'dashboard_order_invoice_first',
    defaultMessage: 'Voila! Your request has been sent to the seller.',
  },
  text2: {
    id: 'dashboard_order_invoice_second',
    defaultMessage:
      'The seller will soon make the receipt available on your Back Market account.',
  },
  text3: {
    id: 'dashboard_order_invoice_third',
    defaultMessage:
      "We'll also keep you posted through email, just to be on the safe side.",
  },
  button: {
    id: 'dashboard_order_invoice_button',
    defaultMessage: 'Great',
  },
}
