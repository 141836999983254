<template>
  <div class="">
    <RevLink
      class="flex h-full flex-col justify-center lg:p-12"
      :class="productLinkClasses"
      :data-qa="`orders-orderitem-${orderlineId}-go-to-product`"
      data-test="orderline-product"
      :to="productLink"
    >
      <picture>
        <RevIllustrationSource
          :height="120"
          :media="BreakpointQueries[Breakpoint.LG]"
          :src="product?.image"
          :width="120"
        />

        <RevIllustration
          :alt="product?.title"
          :height="90"
          :src="product?.image"
          :width="80"
        />
      </picture>
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import type { LinkInternal } from '@backmarket/http-api'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import {
  Breakpoint,
  BreakpointQueries,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { RevLink } from '@ds/components/Link'

import { PRODUCT } from '@/scopes/product/route-names'

import type { OrderlineCardProductProps } from './OrderlineCardProduct.types'

const props = defineProps<OrderlineCardProductProps>()

const injectLocale = useRouteLocationWithLocale()

const productLink = computed(() => {
  const { slugV2, uuid } = (props.product?.link as LinkInternal)?.params || {}

  if (isEmpty(slugV2) || isEmpty(uuid)) return undefined

  return toNuxtLink(
    injectLocale({
      type: 'internal',
      name: PRODUCT.HOME,
      params: {
        slugV2,
        uuid,
      },
    }),
  )
})

const productLinkClasses = computed(() => {
  return productLink.value ? tw`cursor-pointer` : tw`cursor-default`
})
</script>
