import type { OrderlineBundledMobilePlan } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { openModal } from '@ds/components/ModalBase'

import { CARE_COMMON_MODALS_NAMES } from '@/scopes/care-commons/modals/names'

export type MobilePlanActionsConfig = {
  [key in OrderlineBundledMobilePlan['actions'][number]['type']]: (
    item: OrderlineBundledMobilePlan['actions'][number],
  ) => {
    onClick?: ({
      orderlineId,
    }: {
      orderlineId: string | number
    }) => Promise<void>
  }
}

export const MOBILE_PLAN_ACTIONS_CONFIG: MobilePlanActionsConfig = {
  redirect_to_partner_portal: () => ({
    onClick: async ({ orderlineId }) => {
      openModal(
        `${CARE_COMMON_MODALS_NAMES.mobileServiceRedirectModal}-${orderlineId}`,
      )
    },
  }),
}
